<template>
  <header class="navbar">
    <div class="navbar-brand">
      <router-link
        class="navbar-item"
        to="/"
      >
        <img
          class="logo"
          src="@/assets/logo-microanalisis-vertical.png"
          alt="Microimport"
        >
      </router-link>

      <div
        class="navbar-burger burger"
        data-target="navMenu"
        @click="toggleSidebar"
      >
        <span/>
        <span/>
        <span/>
      </div>
    </div>

    <div
      id="navMenu"
      class="navbar-menu"
      :class="{ 'is-active': sidebar }"
    >
      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link has-text-weight-bold is__flex">
            {{ authenticationName }}
          </a>

          <div class="navbar-dropdown is-right">
            <router-link class="navbar-item" :to="{ name: 'mi-perfil' }">
              <img class="icon_dropdown" src="@/assets/images/icon/icon_profile.png" alt="Profile">
              Mi perfil
            </router-link>
            <a class="navbar-item" @click="signOut">
              <img class="icon_dropdown" src="@/assets/images/icon/icon_logout.png" alt="Logout">
              Cerrar Sesión
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'TheHeader',
  computed: {
    ...mapState({
      authenticationName: state => state.authentication.name,
      sidebar: state => state.ui.sidebar
    })
  },
  methods: { ...mapMutations(['toggleSidebar', 'signOut']) }
}
</script>

<style lang="scss" scoped>
  .navbar {
    background-color: white;
    min-height: 90px;
    padding: 0 1rem;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  }

  img.logo {
    max-width: 170px;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
  }
  .navbar-item img {
    max-height: 200px;
  }

  a {
    font-size: 16px;
    color: var(--primary-black);
  }

  .is__flex {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .navbar-link:not(.is-arrowless) {
    padding-right: 3rem;
  }

  .navbar-link:not(.is-arrowless)::after {
    border-color: var(--primary-black);
  }

  .toggle-menu {
    cursor: pointer;
  }

  .navbar-menu .navbar-dropdown .navbar-item {
    display: flex;
    align-items: center;
  }

  .navbar-burger {
    height: inherit;
  }

  .navbar-dropdown {
    border-top: none;
    box-shadow: 0 13px 20px 0 rgba(0, 0, 0, 0.16);
    border-radius: 0;
  }
  .navbar-dropdown a:hover {
    color: var(--primary-green) !important;
  }

  a.navbar-item,
  .navbar-link,
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    background-color: transparent !important;
    color: var(--primary-black);
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: -1rem;
  }

  .icon_dropdown {
    width: 18px;
    margin-right: 10px;
  }

  @media screen and (max-width: 1023px) {
    .navbar {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1.5rem;
    }
    .navbar-burger {
      color: var(--primary-black);
    }
    .navbar-menu {
      background-color: transparent;
      box-shadow: none;
    }
    .navbar-dropdown {
      box-shadow: none;
    }
    .navbar-link:not(.is-arrowless)::after {
      display: none;
    }
    .navbar-link:focus-within:not(.is-arrowless) {
      padding: 0.5rem 0.75rem;
    }
    .navbar-dropdown .navbar-item, .navbar-link {
      padding: 0.5rem 0.75rem;
    }
  }
</style>
